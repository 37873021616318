import React from "react";
import tw, { css } from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const PG = ({ children }) => {
	const data = useStaticQuery(
		graphql`
			query {
				file: file(relativePath: { eq: "SiteBG.jpg" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	);
	return (
		<div css={tw`absolute w-full h-full`}>
			<BackgroundImage
				Tag="section"
				fluid={data.file.childImageSharp.fluid}
				tw="antialiased w-screen min-h-screen py-5 px-5 bg-projectBlue-800 bg-scroll bg-cover bg-no-repeat bg-fixed bg-center font-centra"
			>
				{children}
			</BackgroundImage>
		</div>
	);
};

export default PG;
